import { defineStore } from 'pinia';
import type { NavItem } from '~~/types/NavItem';

import { useCurrentWorkspace } from '~/composables/workspaces/useCurrentWorkspace';

export const useNavSignaturesStore = defineStore('nav-signatures', {
  state: () => ({
    items: computed(() => {
      const workspace = useCurrentWorkspace();

      return !workspace.value
        ? []
        : [
            {
              name: 'workspace-signatures',
              label: 'Signatures',
              icon: 'material-symbols:signature-rounded',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.signatures'],
            },
          ];
    }) as Ref<NavItem[]>,
  }),
  getters: {},
  actions: {},
});
